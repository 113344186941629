import React from "react";

type TwoColumnContentSectionProps = {
  isColReverse: boolean;
  image: string;
  title?: string;
  description?: string;
};

const TwoColumnContentSection = ({
  isColReverse,
  title,
  description,
  image,
}: TwoColumnContentSectionProps) => (
  <div
    className={`leading-[1.5] flex flex-col md:flex-row items-center gap-x-14 gap-y-8 my-[96px] ${
      isColReverse && "md:flex-row-reverse"
    }`}
  >
    <div className="flex-1 text-[#262E32]">
      <h3 className="mb-6 dsc-h3 font-bold leading-[32px] text-[#1B2733]">
        {title}
      </h3>
      <p className="mb-6 dsc-text-sm">{description}</p>
    </div>
    <div className="flex-1">
      <img
        src={image}
        alt={title}
        className="w-full h-full max-h-[345px] object-fill aspect-auto"
      />
    </div>
  </div>
);

export default TwoColumnContentSection;
