import React from "react";
import Button from "../../common/Button";
import { Link } from "gatsby";
interface HeroType {
  title: string;
  description: string;
  bg?: string;
}
const ProductHero = (props: HeroType) => {
  const image = props.bg;
  return (
    <section
      className={`flex flex-col gap-12 px-4 md:px-8 py-24 bg-cover bg-center bg-no-repeat font-noto md:flex-row`}
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <div className="flex flex-col justify-center md:w-[50%]">
        <h1 className="mb-5 font-bold dsc-h1">{props.title}</h1>
        <p className="mb-8 dsc-text">{props.description}</p>
        <div>
          <Link href="/contact-us">
            <Button variant="normal">お問い合わせ</Button>
          </Link>
        </div>
      </div>
    </section>
  );
};
export default ProductHero;
