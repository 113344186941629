import React from "react";

interface CardProps {
  id: number;
  title: string;
  description: string;
}
interface BenefitProps {
  title: string;
  cards: CardProps[];
}

const Benefit = ({ data }: { data: BenefitProps }) => {
  return (
    <div className="md:my-[96px] mb:[0px]">
      <h2 className="block mb-12 font-bold text-center dsc-h2">{data.title}</h2>
      <div className="flex flex-wrap justify-start p-4 md:gap-x-8 xl:gap-x-10 gap-y-16 md:p-0">
        {data.cards.map((item) => (
          <div key={item.id} className="w-full md:w-[30%] flex flex-col">
            <div className="flex items-center mb-6 gap-x-3 min-h-14">
              <h4 className="font-bold dsc-h4 leading-8 text-[#1B2733] ">
                {item.title}
              </h4>
            </div>

            <p className="mb-4 text-base leading-[24px] text-[#262E32]">
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Benefit;
