import React from "react";
import ProductHero from "../../components/products/hero";
import TwoColumns from "../../components/products/banner";
import Container from "../../layout/container";
import Benefits from "../../components/benefit";
import Meta from "../../components/common/meta";
const data = [
  {
    id: 1,
    title: "既存の自動化を活用して気軽に始めましょう",
    description:
      "既に投資してきたスクリプトや、ツール、APIコール、または書面化したマニュアルの手順を活用して、数分で開始できます。プログラミングの必要はありません。",
    isReverse: true,
    image: "/products/incident/one.webp",
  },
  {
    id: 2,
    title: "PagerDutyから自動化アクションを呼び出せます",
    description:
      "Runbook Automationを使用して、Opsアクションへのセルフサービスアクセスを有効にします。労力を減らし、価値の高い仕事に集中してください。",
    isReverse: false,
    image: "/products/incident/one.webp",
  },
  {
    id: 3,
    title: "コンプライアンスとセキュリティ体制を強化する",
    description:
      "きめ細かいアクセス制御とすべてのRundeckアクションの完全な監査証跡により、完全なコンプライアンスの実現を可能にします。リモートアクセス用の機密性の高いセキュリティクレデンシャルは、ローカルマシンではなくRundeckに安全に保存しましょう。",
    isReverse: true,
    image: "/products/incident/three.webp",
  },
];
const defaultMeta = {
  title:
    "インシデント対応の自動化｜Rundeck正規販売代理店 DXable",
  description:
    "Rundeckは、インシデント復旧時間とコストの大幅削減を実現するランブックオートメーションツールです。スクリプトやcronによる定型作業のランブック登録で、誰でも安全にタスクを実行できます。",
  url: "/incident-response",
};
const heroData = {
  title: "インシデント対応ライフサイクル全体を自動化",
  description:
    "PagerDutyとRundeckを使えば、より迅速な解決とインシデントの削減が可能です",
  bg: "/products/incident/incident.svg",
};

const benefitData = {
  title: "インシデントの解決を自動化し、ビジネスをデジタル化",
  cards: [
    {
      id: 1,
      title: "解決までの平均時間を短縮",
      description:
        "一般的な診断および修正作業をオペレーターに委任し、最初のレスポンダーが呼び出される前に診断アクションを実行し、ルーティンな課題の解決を自動化します。",
    },
    {
      id: 2,
      title: "エンジニアリングの生産性を向上",
      description:
        "サービスオーナーシップとは、開発者が全てのアラートに対応することではありません。より少ないエスカレーションでより迅速にインシデントを解決し、開発者を本来のソフトウェア開発業務に集中させることができます。",
    },
    {
      id: 3,
      title: "チームのイノベーション能力の向上",
      description:
        "価値の低いタスクや労力を自動化することで、エンジニアはデジタルエクスペリエンスの向上やビジネスを前進させるその他の価値の高い活動に集中できます。",
    },
  ],
};
const IncidentReport = () => {
  return (
    <Container>
      <Meta meta={defaultMeta} />
      <ProductHero {...heroData} />
      <div className=" md:px-8">
        <Benefits data={benefitData}></Benefits>
        <div className="p-4 md:p-0">
          {data.map((sec) => (
            <TwoColumns
              key={sec.id}
              isColReverse={sec.isReverse}
              image={sec.image}
              title={sec.title}
              description={sec.description}
            />
          ))}
        </div>
      </div>
    </Container>
  );
};
export default IncidentReport;
